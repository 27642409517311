/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('users', userService);

function userService(http, toast) {
    let url = config.apiURL + 'users/', errors = [401, 403, 422, 500];
    function errorHandler(err) {
        if (err && err.data && err.data.message) {
            return Promise.reject(err.data.message);
        }
        return Promise.reject(err);
    }
    function prepareFilters(filters) {
        let f = '';
        for (let i in filters) {
            f += (f === '' ? '?' : '&') + 'filter[' + i + ']=' + filters[i];
        }
        return f;
    }
    return {
        get: (by = {}, page = 1, pageSize) => {
            let _url = url, params = [];
            if (by.id) {
                _url += by.id;
            }
            if (by.role) {
                params.push('filter[role]=' + by.role);
            }
            if (page > 1) {
                params.push('page=' + parseInt(page));
            }
            if (pageSize) {
                params.push('pageSize=' + parseInt(pageSize));
            }
            if (params.length > 0) {
                _url += '?' + params.join('&');
            }
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data.count && res.data.rows) {
                        for (let i = 0; i < res.data.rows.length; i++) {
                            let row = res.data.rows[i];
                            if (row.permissions !== '') {
                                row.permissions = JSON.parse(row.permissions);
                            }
                        }
                        resolve({
                            count: res.data.count,
                            items: res.data.rows
                        });
                    }
                    resolve(res.data);
                }, (err) => {
                    if (err) {
                        if (err.message) {
                            if (err.type !== 'HTTPService') {
                                return reject(err.message);
                            } else {
                                toast.show(err.message, 'error', true);
                            }
                        }
                        reject(err);
                    }
                    reject();
                });
            });
        },
        add: (user) => {
            return http.post(url, user)
                    .then((res) => {
                        return Promise.resolve(res);
                    }).catch(errorHandler);
        },
        update: (user, isCurrentUser = false) => {
            return new Promise((resolve, reject) => {
                http.put(url + (isCurrentUser ? 'me' : user._id), user).then((resp) => {
                    resolve(resp);
                }, (resp) => {
                    if (errors.indexOf(resp.status) > -1) {
                        reject(resp.data.message);
                    }
                });
            });
        },
        delete: (user) => {
            return new Promise((resolve, reject) => {
                http.delete(url + (user._id ? user._id : user)).then((resp) => {
                    resolve(resp);
                }, (err) => {
                    if (err.message) {
                        reject(err);
                    }
                    if (errors.indexOf(err.status) > -1) {
                        reject(err.data.message);
                    }
                });
            });
        },
        logout: () => {
            let _url = url + 'me/logout';
            return new Promise((resolve, reject) => {
                http.put(_url).then(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        },
        getConnections: (userId, filters) => {
            if (userId === null) {
                return Promise.reject('NoData');
            }
            let f = prepareFilters(filters);
            let _url = url + userId + '/connections' + f;
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        }
    };
}

